export const customScrollDesign = {
  '::-webkit-scrollbar': {
    width: '5px',
  },

  '::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },

  '::-webkit-scrollbar-thumb': {
    background: '#cfcfcf',
    borderRadius: '5px',
  },
};

export const test = '';
